<template>
    <div :style="$lang == 2 ? {direction:'ltr'} : {direction:'rtl'}">
        <Card>
            <p slot="title">{{$t('menu_name.product_title')}}</p>

            <Row :gutter="20">
                <Col :span="8">
                    <Input search enter-button :placeholder="$t('search.search_placeholder')" @on-search="search" size="large"/>
                </Col>
                <Row :span="2">
                    <el-button icon="el-icon-plus" @click="create">{{$t('create_submit')}}</el-button>
                </Row>
                <Col :span="2">
                    <download-excel :header="$t('customer_table.xls_table_header')" class="export-excel-wrapper" :data="tableData" :fields="$lang == 2 ? jsonFile : jsonFileUy" name="商品管理.xls">
                        <el-button icon="el-icon-download">{{$t('export_submit')}}</el-button>
                    </download-excel>
                </Col>
            </Row>

            <div style="padding: 10px">

                <el-table
                        :data="tableData"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        max-height="750"
                        style="width: 100%"
                        v-loading="tableLoading">
                    <el-table-column
                            prop="id"
                            type="index"
                            label="ID">
                    </el-table-column>
                    <el-table-column
                            prop="image"
                            :label="$t('customer_table.image')">
                        <template slot-scope="scope">
                            <el-image :src="$appUrl + scope.row.image"
                                      style="border-radius: 10px;width: 50px;height: 50px;cursor: pointer"
                                      :preview-src-list="srcList"
                                      @click="loadImage(scope.row.image)">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="name_ug"
                            :label="$t('customer_table.name_ug')">
                    </el-table-column>
                    <el-table-column
                            prop="name_zh"
                            :label="$t('customer_table.name_zh')">
                    </el-table-column>
                    <el-table-column
                            prop="product_no"
                            :label="$t('customer_table.product_no')">
                    </el-table-column>
                    <el-table-column
                            prop="category"
                            :label="$t('customer_table.category')">
                    </el-table-column>
                    <el-table-column
                            prop="unit"
                            :label="$t('customer_table.unit')">
                    </el-table-column>
                    <el-table-column
                            prop="price_prifx"
                            :label="$t('customer_table.price')">
                    </el-table-column>
                    <el-table-column
                            prop="state"
                            :label="$t('customer_table.state')">
                        <template slot-scope="scope">
                            <div v-if="scope.row.state == $t('state_ok')">
                                <el-tag>{{scope.row.state}}</el-tag>
                            </div>
                            <div v-else>
                                <el-tag type="danger">{{scope.row.state}}</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="created_at"
                            width="140"
                            :label="$t('customer_table.created_at')">
                    </el-table-column>
                    <el-table-column
                            :label="$t('customer_table.operation')"
                            width="300">
                        <template slot-scope="scope">
                            <Row style="text-align: center;justify-items: center;margin: 0 auto" :gutter="3">
                                <Col :span="8">
                                    <el-button icon="el-icon-view" @click="handleView(scope.row)" size="small">
                                        {{$t("customer_table.show_submit")}}
                                    </el-button>
                                </Col>
                                <Col :span="8">
                                    <el-button icon="el-icon-edit" @click="handleEdit(scope.row)" type="primary" size="small">
                                        {{$t("customer_table.edit_submit")}}
                                    </el-button>
                                </Col>
                                <Col :span="8">
                                    <el-button icon="el-icon-delete" @click="handleDelete(scope.row)" type="danger" size="small">
                                        {{$t("customer_table.delete_submit")}}
                                    </el-button>
                                </Col>
                            </Row>

                        </template>
                    </el-table-column>
                </el-table>

                <div style="text-align: center;margin-top: 30px;">
                    <el-pagination
                            background
                            layout="prev, pager, next"
                            :total="total"
                            :page-size="pagesize"
                            @current-change="current_change">
                    </el-pagination>
                </div>

            </div>
        </Card>

        <div style="direction: ltr">
            <el-dialog
                    :title="type == 1 ? $t('system_create') : $t('system_edit')"
                    :visible.sync="dialogVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    :before-close="handleClose">
                <Form ref="customerRef" :model="customerForm" :rules="customerRules" :style="$lang == 2 ? {direction:'ltr'} : {direction:'rtl'}" :label-width="80">
                    <Row :gutter="20">
                        <Col :span="24">
                            <FormItem :label="$t('customer_table.image')">
                                <upload-image :file-list="logo_avatars" action="customer/cms_up_image" :type="true" :limit="1" :multiple="false" @images="avatar"></upload-image>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem :label="$t('customer_table.name_zh')" prop="name_zh">
                                <Input v-model="customerForm.name_zh" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_name_zh')" size="large"></Input>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem :label="$t('customer_table.name_ug')" prop="name_ug">
                                <Input v-model="customerForm.name_ug" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_name_ug')" size="large"></Input>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem :label="$t('customer_table.product_no')" prop="product_no">
                                <Input v-model="customerForm.product_no" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_product_no')" size="large"></Input>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem :label="$t('customer_table.p_company')" prop="p_company_id">
                                <el-select v-model="customerForm.p_company_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                    <el-option
                                            v-for="item in pCompany"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem :label="$t('customer_table.category_title')" prop="categoey_id">
                                <el-select v-model="customerForm.category_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                    <el-option
                                            v-for="item in category"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem :label="$t('customer_table.unit')" prop="unit_id">
                                <el-select v-model="customerForm.unit_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                    <el-option
                                            v-for="item in unit"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem :label="$t('customer_table.child_unit_val')" prop="child_unit_id">
                                <el-select v-model="customerForm.child_unit_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                    <el-option
                                            v-for="item in unit"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem :label="$t('customer_table.price')" prop="price">
                                <Input v-model="customerForm.price" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_price')" size="large"></Input>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem :label="$t('customer_table.case_in_num')" prop="case_in_num">
                                <Input v-model="customerForm.case_in_num" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_case_in_num')" size="large"></Input>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem :label="$t('customer_table.validity')" prop="validity">
                                <Input v-model="customerForm.validity" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_validity')" size="large"></Input>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem :label="$t('customer_table.net_content')" prop="net_content">
                                <Input v-model="customerForm.net_content" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_net_content')" size="large"></Input>
                            </FormItem>
                        </Col>
                        <Col :span="24">
                            <FormItem :label="$t('customer_table.images')">
                                <upload-image :file-list="shop_image" action="customer/cms_up_image" :type="true" :limit="$limit" :multiple="true" @images="images"></upload-image>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem prop="state">
                                <el-radio-group v-model="customerForm.state" size="medium">
                                    <el-radio :label="1" border>{{$t('state_ok')}}</el-radio>
                                    <el-radio :label="0" border>{{$t('state_fail')}}</el-radio>
                                </el-radio-group>
                            </FormItem>
                        </Col>
                    </Row>



                </Form>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="handleClose">{{$t('cancel_button')}}</el-button>
                    <el-button type="primary" @click="customerCreate('customerRef')">{{$t('confrim_button')}}</el-button>
                </div>

            </el-dialog>
            <el-drawer
                    :visible.sync="drawer"
                    :direction="direction"
                    size="40%"
                    :before-close="handleCloseDrawer">
                <div slot="title">
                    <div style="font-size: 20px">
                        {{$t('system_show')}}
                    </div>

                </div>
                <div :style="$lang == 2 ? {direction:'ltr',padding: '10px'} : {direction:'rtl',padding: '10px'}">
                    <div style="text-align: center">
                        <el-image :src="$appUrl + productView.image" style="width: 130px;height: 130px;border-radius: 90px;border: 6px solid #f4f4f4;box-shadow: 4px 8px 12px #f4f4f4">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>

                    <el-descriptions
                            :labelStyle="$lang == 2 ? {'text-align':'left'} : {'text-align':'right'}"
                            :content-style="$lang == 2 ? {'text-align':'left'} : {'text-align':'right'}" :column="1" style="margin-top: 5%" border>
                        <el-descriptions-item>
                            <template slot="label">
                                {{$t('customer_table.name_zh')}}
                            </template>
                            {{productView.name_zh}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                {{$t('customer_table.name_ug')}}
                            </template>
                            {{productView.name_ug}}
                        </el-descriptions-item>
                        <el-descriptions-item span="1">
                            <template slot="label">
                                {{$t('customer_table.product_no')}}
                            </template>
                            {{productView.product_no}}
                        </el-descriptions-item>
                        <el-descriptions-item :span="1">
                            <template slot="label">
                                {{$t('customer_table.company')}}
                            </template>
                            {{productView.company}}
                        </el-descriptions-item>
                        <el-descriptions-item :span="1">
                            <template slot="label">
                                {{$t('customer_table.category')}}
                            </template>
                            {{productView.category}}
                        </el-descriptions-item>
                        <el-descriptions-item :span="1">
                            <template slot="label">
                                {{$t('customer_table.unit')}}
                            </template>
                            {{productView.unit}}
                        </el-descriptions-item>
                        <el-descriptions-item :span="1">
                            <template slot="label">
                                {{$t('customer_table.case_in_num')}}
                            </template>
                            {{productView.case_in_num}}
                        </el-descriptions-item>
                        <el-descriptions-item :span="1">
                            <template slot="label">
                                {{$t('customer_table.net_content')}}
                            </template>
                            {{productView.net_content}}
                        </el-descriptions-item>
                        <el-descriptions-item :span="1">
                            <template slot="label">
                                {{$t('customer_table.validity')}}
                            </template>
                            {{productView.validity}}
                        </el-descriptions-item>
                        <el-descriptions-item :span="1">
                            <template slot="label">
                                {{$t('customer_table.price')}}
                            </template>
                            {{productView.price_prifx}}
                        </el-descriptions-item>
                        <el-descriptions-item  :span="1">
                            <template slot="label">
                                {{$t('customer_table.state')}}
                            </template>
                            <div v-if="productView.state == $t('state_ok')">
                                <el-tag>{{productView.state}}</el-tag>
                            </div>
                            <div v-else>
                                <el-tag type="danger">{{productView.state}}</el-tag>
                            </div>
                        </el-descriptions-item>
                        <el-descriptions-item :span="1">
                            <template slot="label">
                                {{$t('customer_table.images')}}
                            </template>
                            <el-image v-for="(item,index) in productView.images"
                                      :src="$appUrl + item.url"
                                      :preview-src-list="itemsShowImage"
                                      :key="index"
                                      style="width: 70px;height: 70px;padding: 5px;">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </el-descriptions-item>
                    </el-descriptions>

                </div>
            </el-drawer>
        </div>


    </div>
</template>

<script>
    import UploadImage from "@/components/UploadImage";
    import UploadImageEdit from "@/components/UploadImageEdit";
    export default {
        name: "product",
        data(){
            return{
                drawer: false,
                direction: this.$lang == 1 ? 'rtl' : 'ltr',

                itemsShowImage:[],


                tableLoading:true,

                tableData: [],

                srcList:[],

                dialogVisible:false,


                pCompany:[],
                category:[],
                unit:[],

                logo_avatars:[],
                shop_image:[],

                customer_id:0,


                total:0,
                pagesize:10,
                currentPage:1,



                //type  判断时新增还是编辑
                type:0,
                customerForm:{
                    name_ug:'',
                    name_zh:'',
                    image:'',
                    images:[],
                    product_no:'',
                    p_company_id:'',
                    category_id:'',
                    unit_id:'',
                    case_in_num:'',
                    price:'',
                    validity:'',
                    net_content:'',
                    child_unit_id:'',

                    state:1,

                },

                productView:'',

                customerRules:{
                    name_zh:[
                        {required:true,message:this.$i18n.t("customer_table.create_name_zh"),trigger:'blur'}
                    ],
                    name_ug:[
                        {required:true,message:this.$i18n.t("customer_table.create_name_ug"),trigger:'blur'}
                    ],
                    product_no:[
                        {required:true,message:this.$i18n.t("customer_table.create_product_no"),trigger:'blur'}
                    ],
                    case_in_num:[
                        {required:true,message:this.$i18n.t("customer_table.create_case_in_num"),trigger:'blur'}
                    ],
                    price: [
                        {required:true,message:this.$i18n.t("customer_table.create_price")}
                    ],
                    validity: [
                        {required:true,message:this.$i18n.t("customer_table.create_validity"),trigger:'blur'}
                    ],
                    net_content: [
                        {required:true,message:this.$i18n.t("customer_table.create_net_content"),trigger:'blur'}
                    ],
                },

                jsonFile:{
                    ID:'id',
                    维语昵称:'name_ug',
                    汉语昵称:'name_zh',
                    商品编号:'product_no',
                    数量:'case_in_num',
                    价格:'price',
                    保质期:'validity',
                    净含量:'net_content',
                    状态:'state',
                    创建时间:'created_at',
                },
                jsonFileUy:{
                    ID:'id',
                    'ئۇيغۇرچە ئىسمى':'name_ug',
                    'خەنزۇچە ئىسمى':'name_zh',
                    'مەھسۇلات نۇمۇر':'product_no',
                    'سان':'case_in_num',
                    'باھاسى':'price',
                    'مۇددىتى':'validity',
                    'ساپ ئېغىرلىقى':'net_content',
                    'ھالىتى':'state',
                    'قېتىلغان ۋاقتى':'created_at',
                }


            }
        },
        components:{UploadImage},
        mounted() {
            this.show();
        },
        methods:{
            search(e){
                //第一次请求获取列表
                this.$http.get('cms_api/listCmsProductCmsProduct',{
                    params:{
                        keyword:e,
                        page:1,
                        limit:10
                    }
                }).then((res)=>{
                    this.tableData = res.data.items
                    this.total = res.count
                })
            },
            show(){
                this.$http.get('cms_api/listCmsProductCmsProduct',{
                    params:{
                        keyword:'',
                    }
                }).then((res)=>{
                    this.tableData = res.data.items
                    this.total = res.count
                })
            },
            create(){
                this.type = 1; // 新增标识
                this.dialogVisible = true;
                this.$http.get('cms_api/selectCms')
                    .then((res)=>{
                        this.pCompany = res.data.company
                        this.category = res.data.productCategory
                        this.unit = res.data.unit
                    })

            },
            handleClose(){
                this.$refs.customerRef.resetFields();

                this.dialogVisible = false;
                this.customerForm.name_ug = '';
                this.customerForm.name_zh = '';
                this.customerForm.image = '';
                this.customerForm.product_no = '';
                this.customerForm.p_company_id = '';
                this.customerForm.category_id = '';
                this.customerForm.unit_id = '';
                this.customerForm.child_unit_id = '';
                this.customerForm.case_in_num = '';
                this.customerForm.price = '';
                this.customerForm.validity = '';
                this.customerForm.net_content = '';

                this.logo_avatars = [];
                this.shop_image   = [];

            },
            current_change(page){
                this.currentPage = page;
                this.$http.get('cms_api/listCmsProductCmsProduct',{
                    params:{
                        keyword:'',
                        page:page,
                        limit:10
                    }
                }).then((res)=>{
                    this.tableData = res.data.items
                    console.log(this.tableData)
                    this.total = res.count == undefined ? 0 : res.count
                })
            },
            handleView(row){
                this.productView = row
                this.productView.images.forEach((item,index)=>{
                    this.itemsShowImage.push(this.$appUrl + item.url)
                })
                this.drawer = true
            },
            handleEdit(row){
                this.type = 2
                var editDate = row

                this.$http.get('cms_api/selectCms')
                    .then((res)=>{
                        this.pCompany = res.data.company
                        this.category = res.data.productCategory
                        this.unit = res.data.unit
                    })

                this.customerForm = {
                    name_ug:editDate.name_ug,
                    name_zh:editDate.name_zh,
                    image: editDate.image,
                    images:[],
                    product_no:editDate.product_no,
                    p_company_id:editDate.p_company_id,
                    category_id:editDate.category_id,
                    child_unit_id:editDate.child_unit_id,
                    unit_id:editDate.unit_id,
                    case_in_num:editDate.case_in_num,
                    price:editDate.price,
                    validity:editDate.validity,
                    net_content:editDate.net_content,
                    state:editDate.states,
                }

                this.logo_avatars = [
                    {name:'avatar',url:editDate.image_prefix}
                ];
                if (editDate.images.length > 0){
                    editDate.images.forEach((item,index)=>{
                        this.shop_image.push({
                            name:item.name,
                            url:this.$appUrl + item.url
                        })
                    })
                }

                editDate.images.forEach((item,index)=>{
                    this.customerForm.images.push(item.url)
                })

                this.customer_id = editDate.id

                this.dialogVisible = true
            },
            handleDelete(row){
                var id  = row.id
                this.$msConMessage.confirm(this.$i18n.t('system_delete'),this.$i18n.t('system'),{
                    confirmButtonText:this.$i18n.t('confrim_button'),
                    cancelButtonText:this.$i18n.t('cancel_button'),
                    type:'warning',
                    closeOnClickModal:false
                }).then((res)=>{
                    this.$http.post('cms_api/deleteProduct',{
                        id:id
                    }).then((res)=>{
                        if (res.status == 200){
                            this.show()
                        }
                    })
                }).catch(()=>{
                    return true;
                })
            },
            customerCreate(formLogin){

                var customerForm = this.customerForm;
                this.$refs[formLogin].validate((valid)=>{
                    if (valid){
                        if (customerForm.category_id == ''){
                            this.$msMessage.error(this.$i18n.t('system_require'))
                        }else if(customerForm.images.length <= 0){
                            this.$msMessage.error(this.$i18n.t('customer_table.empty_images'));
                        }else if (customerForm.image == '') {
                            this.$msMessage.error(this.$i18n.t('customer_table.empty_avatar_upload'));
                        }else if (customerForm.p_company_id == '') {
                            this.$msMessage.error(this.$i18n.t('system_require'));
                        }else if (customerForm.unit_id == '') {
                            this.$msMessage.error(this.$i18n.t('system_require'));
                        }else if (customerForm.child_unit_id == '') {
                            this.$msMessage.error(this.$i18n.t('system_require'));
                        }else{
                            if (this.type == 1){
                                this.$http.post('product/create',{
                                    name_ug:customerForm.name_ug,
                                    name_zh:customerForm.name_zh,
                                    image: customerForm.image,
                                    images:customerForm.images,
                                    product_no:customerForm.product_no,
                                    p_company_id:customerForm.p_company_id,
                                    category_id:customerForm.category_id,
                                    unit_id:customerForm.unit_id,
                                    child_unit_id:customerForm.child_unit_id,
                                    case_in_num:customerForm.case_in_num,
                                    price:customerForm.price,
                                    validity:customerForm.validity,
                                    net_content:customerForm.net_content,
                                    state:customerForm.state,

                                }).then((res)=>{
                                    this.handleClose()
                                    this.dialogVisible = false;
                                    this.show()
                                })

                            }else{

                                this.$http.post('cms_api/updateProducts',{
                                    id:this.customer_id,
                                    name_ug:customerForm.name_ug,
                                    name_zh:customerForm.name_zh,
                                    image: customerForm.image,
                                    images:customerForm.images,
                                    product_no:customerForm.product_no,
                                    p_company_id:customerForm.p_company_id,
                                    category_id:customerForm.category_id,
                                    unit_id:customerForm.unit_id,
                                    child_unit_id:customerForm.child_unit_id,
                                    case_in_num:customerForm.case_in_num,
                                    price:customerForm.price,
                                    validity:customerForm.validity,
                                    net_content:customerForm.net_content,
                                    state:customerForm.state,
                                }).then((res)=>{
                                    this.handleClose()
                                    this.dialogVisible = false;
                                    this.show()
                                })

                            }

                        }
                    }else{
                        return false;
                    }
                })

            },
            images(res){
                var images = res;
                this.customerForm.images.push(images.image_url)
            },
            avatar(res){
                this.customerForm.image = res.image_url
            },
            loadImage(res){
                this.srcList = [this.$appUrl + res]
            },
            handleCloseDrawer(){
                this.itemsShowImage = []
                this.drawer = false
            }

        },
    }
</script>

<style scoped>
    /deep/.el-dialog{
        border-radius: 10px;
    }
    /deep/ .el-upload--picture-card .upload_avatar{
        border-radius: 120px;
    }
</style>