<template>
    <div>
        <el-upload
                :action="$baseUrl + action"
                :list-type="listType"
                :on-success="handleAvatarSuccess"
                :multiple="multiple"
                accept="image/jpeg,image/jpg,image/png"
                :headers="headers"
                :limit="limit"
                :file-list="fileList"
                :on-exceed="handleExceed"
                :on-change="upldchange"
                :before-upload="beforeUpload">
            <i v-show="multiple == true" class="el-icon-plus"></i>
            <slot name="isautoload"></slot>
        </el-upload>
    </div>
</template>

<script>
    export default {
        name: "UploadImageEdit",
        props:{
            action:{
                type:String
            },
            multiple:{
                default:true
            },
            listType:{
                default:'picture-card'
            },
            limit:{
                default:25
            },
            fileList:{
                type:Array,
                default:[]
            }
        },
        data() {
            return {
                dialogImageUrl: '',
                dialogVisible: false,
                headers:{
                    "Authorization" : `Bearer ${sessionStorage.getItem('token')}`
                }
            }
        },
        methods :{
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);

            },
            beforeUpload(file){
                // const isJPG = file.type === 'image/jpeg';
                // const isJpeg = file.type === 'image/jpg';
                // const isPng = file.type === 'image/png';
                //
                const isLt2M = file.size / 1024 / 1024 < 3;
                //
                // if (!isPng) {
                //     this.$msMessage.error('格式错误/jpg!');
                // }

                // }else if (!isJpeg){
                //     this.$msMessage.error('格式错误/jpeg!');
                // }else if (!isPng){
                //     this.$msMessage.error('格式错误/png!');
                // }

                if (!isLt2M) {
                    this.$msMessage.error('图片大小不能超过 3MB!');
                }
                return isLt2M;
            },
            upldchange(file, fileList){
                this.$Spin.show();
                if (file.status == 'success'){
                    this.$Spin.hide();
                    this.$emit('images',file.response)
                }

            },
            handleExceed(files, fileList){
                this.$msMessage.warning(this.$i18n.t('customer_table.avatar_stop'));
            }
        }
    }
</script>

<style scoped>

</style>