module.exports = {
    system:'سىستىما ئەسكەرتىلمىسى',
    system_statistic:'سىستېما يېقىنقى 30 كۈنلۈك سانلىق مەلۇمات كۆرسىتىش چەكلىنىدۇ، تاللاش ۋاقتى پەقەت يېقىنقى 30 كۈنلۈك سانلىق مەلۇماتلىنىلا تاللىيالايدۇ',
    system_three_one:'سىستېما يېقىنقى 60 كۈنلۈك سانلىق مەلۇمات كۆرسىتىش چەكلىنىدۇ، تاللاش ۋاقتى پەقەت يېقىنقى 60 كۈنلۈك سانلىق مەلۇماتلىنىلا تاللىيالايدۇ',
    system_product:'ئاستىدىكى سانلىق مەلۇماتلانى تاللىسىڭىز بۇلدۇ ، كۆپ خىل تاۋارلارنى ئىسكىلاتقا كىرگۈزۈشكە بۇلدۇ',
    out_system_product:'ئاستىدىكى سانلىق مەلۇماتلانى تاللىسىڭىز بۇلدۇ ، كۆپ خىل تاۋارلارنى ئىسكىلاتتىن چىقىرىشقا بۇلدۇ',
    confrim_button:'مۇقۇمداش',
    cancel_button:'ئەمەلدىن قالدۇرۇش',
    logout:'سىستىمىدىن چىكىنىش',
    loading:'سەل ساقلاڭ',
    system_delete:'بۇ مەشغۇلاتىڭىز بۇئۇچۇرنى مەڭگۈ يۇيىدۇ!مەشغۇلاتنى داۋاملاشتۇرامسىز؟',
    system_success_confrim:'مەشغۇلاتنى داۋاملاشتۇرامسىز؟مۇقۇملاشتۇرغاندىن كېيىن قايتۇرغىلى بولمايدۇ!',
    system_success_in_product:'ئەمەلدىن قالدۇرۇشنى مۇقۇمداشتۇرامسىز؟نۆۋەتتە مەھسۇلات تاللاقلىقكەن،ئەمەلدىن قالدۇرغاندىن كىيىن تاللىنىپ بولغان ئۇچۇر تازلىنىپ كىتىدۇ!',
    system_edit:'ئۇچۇر تەھىرلەش',
    system_shows:'ئۇچۇر تەپسىلاتى',
    system_bohui:'قايتۇرۇش سەۋەبى',
    system_require:'تولدۇرۇشغا تىگىشلىك يە ئاققالسا بولمايدۇ',
    system_show:'كۈرۈش',
    system_in_product:'ئىسكىلاتقا كىرگۈزۈش',
    system_out_product:'ئىسكىلاتتىن چىقىرىش',
    system_suppl:'تەستىقلاش',
    system__in_require:'ئىسكىلاتقا كىرگۈزىدىغان مالنى تاللاڭ',
    system__out_require:'ئىسكىلاتتىن چىقىرىدىغان تاۋارنى تاللاڭ',


    payment_mode:'0.0يۇەن',
    xiao_one:'ئامباردىكى مال يىتىشمەيدۇ',
    printer:'دايىن قىلىش',

    is_zro_xiao:'كىچىك بولماسلىقى كىرەك',
    is_wan_xiao:'چوڭ بولماسلىقى كىرەك',



    system_select:'تاللانغىنى',

    system_empty_count:'ئىسكىلاتقا كىرگۈزىدىغان سانىنى كىرگۈزۈڭ ،سان 0 ياكى ئاققالسا بولمايدۇ',
    system_empty_count_out:'ئىسكىلاتقا چىقىرىدىغان سانىنى كىرگۈزۈڭ ،سان 0 ياكى ئاققالسا بولمايدۇ',



    loadings:'يۈكلىنىۋاتىدۇ',
    request_title : 'تور ئۇلنىشتا خاتالىق بار،سەل تۇرۇپ قايتا سىناڭ',
    request_title_image : 'تور ئۇلنىشتا خاتالىق بار،بەتنى يىڭىلىغاندىن كىيىن ياكى تورغا ئۇلغاندىن كىيىن قايتا سىناڭ',

    have:'بار',
    not_have:'يوق',


    empty:'ئۇچۇر يوق',
    empty_detail:'ۋاقتىنچە ئالاقىدەر تەپسىلات ئۇچۇر يوق',
    charts_empty:'ۋاقتىنچە ئۇچۇر يوق',
    select:'ئۇچۇر تاللاڭ',

    content:'قايتۇرۇش سەۋەبىڭىزنى يېزىڭ',



    state_ok:'ئۇچۇق',
    state_fail:'تاقاق',

    system_create:'ئۇچۇر قۇشۇش',
    out_system_create:'مەھسۇلات چىقىرىش',



    create_submit:'قۇشۇش',
    export_submit:'چىقىرىش',

    shenhe_submit:'تەستىقلاش',
    in_mini_submit:'مال چىقىرىش',
    bohui_submit:'قايتۇرۇش',
    download_submit:'رەسىمنى چۈشۈرۈش',

    close_submit:'كۆزنەكنى تاقاش',

    distron_content:'ئاساسلىق ئۇچۇر',
    detail_content:'تەپسىلات ئۇچۇر',



    login:{
        title:'بۆرتالا ئاتلان',
        mobile_placeholder:'تىلفۇن نۇمۇرنى كىرگۈزۈڭ',
        password_placeholder:'پارولنى كەرگۈزۈڭ',
        login_button : 'كىرىش'
    },
    lang_change:{
        title:'中文',
    },
    modal_alert:{
        logout_content:'سىستىمىدىن چىكىنەمسىز؟چىكىنسىڭىز مۇقۇمداش كۇنۇپكىسىى بېسېڭ!ئەمەلدىن قالدۇرماقچى بولسىڭىز ئۈستىدىكى خاتا نى باسسىڭىز بۇلدۇ.'
    },
    menu_name:{
        customer_title:'خېرىدار باشقۇرۇش',
        supplier_title:'تەمىنلىگۈچى باشقۇرۇش',

        product_label:'مەھسۇلات',
        product_title:'مەھسۇلات باشقۇرۇش',
        product_chart_title:'مەھسۇلات ئايلانما رەسىم',
        product_category_title:'مەھسۇلات تۈرى',
        product_unit:'بىرلىكى',
        product_price:'مەھسۇلات باھاسى',
        product_low_stocks:'كەمچىل مال',
        product_out_percent:'مال چىقىرىش سوممىسى',


        user_label:'ئەزا',
        user_title:'ئەزا',
        user_group:'ئەزا گۇرۇپىسى',


        statistic_label:'ئانالىز',
        base_line:'ئامبار قىزىل سىزىقى',
        expire_list:'ۋاقتى ئۆتكەن مال',
        in_statistic:'مال كىرگۈزۈش ئانالىزى',
        inventory_summary:'ئامبار ئومۇمىي ئانالىزى',
        customer_credit_statistic:'نىسى ئانالىزى',
        out_statistic:'مال چىقىرىش ئانالىزى',
        expenditure_category_statistic:'چىقىم تۈرى ئانالىزى',
        check_in_statistic:'يوقلام ئانالىزى',
        in_out_statistic:'ئومۇمىي مۈلۈك ئانالىزى',
        always_statistic:'پايدا زىيان ئانالىزى',
        transactions_statistic:'ئومۇمىي سودا ئانالىزى',

        in_put_product:'مال كىرگۈزۈش چىقىرىش باشقۇرۇش',
        in_product:'مال كىرگۈزۈش',
        out_product:'مال چىقىرىش',

        product_list:'مەھسۇلات باشقۇرۇش',
        in_product_list:'مال كىرگۈزۈش باشقۇرۇش',
        out_product_list:'مال چىقىرىش باشقۇرۇش'

    },
    search:{
        search_placeholder:'ئىزدەيدىغان مەزمۇننى كىرگۈزۈپ ئىزدەڭ'
    },
    customer_table:{
        child_unit_id:'كىچىك بىرلىكىنى تاللاڭ',
        shop_name:'دۇكان ئىسمى',
        name:'ئىسمى',
        product_name:'مەھسۇلات ئىسمى',
        warehouse_name:'ئامبار نامى',
        supplier_name:'تەمىنلىگۈچى',
        supplier_phone:'تەمىنلىگۈچى تىلفۇن نۇمۇر',

        percent_name:'پىرسەنىت سانى',


        out_money:'چىقىم سومما',


        image:'مەھسۇلات رەسىمى',   //这块暂时定为统一
        image_el:'رەسىمى',   //这块暂时定为统一
        product_no:'مەھسۇلات نۇمۇرى',

        product_date:'ئىشلەپ چىقىرىلغان ۋاقتى',

        money_dan:'يەككە باھاسى',

        qrCode:'كود ھاسىللاش',
        share_wechat:'ھەمبەھىرلەش',




        category:'تۈر',
        unit:'بىرلىكى',
        price:'باھاسى',

        number_no:'تالون نۇمۇرى',
        in_type_name:'كىرگۈزۈش تۈرى',
        from_warehouse_name:'تەمىنلىگۈچى',
        time:'ۋاقتى',

        // out
        out_type_name:'چىقىرىش تۈرى',
        to_warehouse_name:'خېرىدار',
        transport_name:'چىقىرىش شەكلى',

        insert_product:'مەھسۇلات قۇشۇش',
        out_insert_product:'مەھسۇلات چىقىرىش',

        wechat_mini_product:'ئەپچاق زاكازلار',
        normal_product:'نورمال زاكازلار',
        sub_warehouse_product:'تارماق ئامبار زاكازلار',




        name_ug:'ئۇيغۇرچە ئىسمى',
        name_zh:'خەنزۇچە ئىسمى',
        contact:'ئالاقىلاشقۇچى',
        phone:'تىلفۇن نۇمۇر',
        created_at:'قېتىلغان ۋاقتى',
        state:'ھالىتى',
        p_company:'تەۋە شىركەت',
        category_title:'مەھسۇلار تۈرى',
        child_unit_val:'پارچە بىرلىك',



        group_name:'ئەزا گۇرۇپىسى',


        discription:'ئەسكەرتىش',



        count:'يەشىك',
        case:'تال',



        operation:'مەشغۇلات',

        edit_submit:'تەھىرلەش',
        delete_submit:'يۇيۇش',
        show_submit:'كۈرۈش',
        company:'شىركەت',
        case_in_num:'سانى',
        net_content:'ساپ ئېغىرلىقى',
        validity:'مۇددىتى',
        images:'مەھسۇلاتقا دائىر رەسىملە',

        create_number_no:'تالون نۇمۇرىنى كىرگۈزۈڭ',

        create_time:'ۋاقىتنى تاللاڭ',


        identifier:'بەلگە',
        description:'چۈشەندۈرلىشى',


        total_money:'ئۇمۇمىي سوممىسى',


        //----------
        in_total : 'مال كىرگۈزۈلگەن سانى',
        in_money: 'مال كىرگۈزۈلگەن سوممىسى',

        out_total: 'مال چىققان سانى',
        out_money: 'مال چىققان سوممىسى',

        total_credit : 'ئومۇمىي نىسى',
        repayment : 'قايتۇرۇپ بولغىنى',
        credit : 'قايتۇرۇشقا تىگىشلىك',
        //--------



        weight:'تەرتىپ',






        create_shop_name:'دۇكان ئىسمىنى كىرگۈزۈڭ',
        create_name_ug:'ئۇيغۇرچە ئىسمىنى كىرگۈزۈڭ',
        create_name_zh:'خەنزۇچە ئىسمىنى كىرگۈزۈڭ',
        create_contact:'ئالاقىلاشقۇچىنى كىرگۈزۈڭ',
        create_phone:'تىلفۇن نۇمۇرنى كىرگۈزۈڭ',
        create_price:'باھاسىنى كىرگۈزۈڭ',
        create_state:'ھالىتىنى تاللاڭ',
        create_address:'ئادرىسنى يېزىڭ',

        create_is_edit_price:'باھا ئۆزگەرتىش ھوقۇقى بارمۇيوق',

        create_reject:'قايتۇرۇش سەۋەبىنى يېزىڭ',


        create_product_no:'مەھسۇلات نۇمۇرىنى كىرگۈزۈڭ',
        create_case_in_num:'سانىنى كىرگۈزۈڭ',
        create_validity:'مۇددىتىنى كىرگۈزۈڭ',
        create_net_content:'ساپ ئېغىرلىقىنى كىرگۈزۈڭ',

        create_weight:'تەرتىپنى كىرگۈزۈڭ',


        create_count:'يەشىك سانىنى كىرگۈزۈڭ',


        middle_price : 'تەنەرىخ باھاسى',

        m_price:'سېتىۋېلىش باھاسى',







        empty_category:'دۇكان تۈرىنى تاللاڭ',
        empty_images:'ئالاقىدەر دۇكان رەسىمنى يوللاڭ',

        xls_table_header:'خېرىدار باشقۇرۇش چىقىرىش ئۇچۇرلىرى',
        avatar_upload : 'باش تەگلىك يوللاش',

        empty_avatar_upload : 'باش تەگلىك رەسىم يوللاڭ',


        avatar_stop:'نۆۋەتتە بىر ھۆججەت تاللاش چەكلەندى،ئەگەر سىز قايتا تاللىماقچى بولسىڭىز رەسىمنىڭ ئوڭ تەرەپتىكى بەلگە نى بېسىپ يۇيۇپ قايتا يوللىسىڭىز بۇلدۇ',

        create_image:'رەسىم يوللاڭ',

        /* 审核 */
        wait_for_review : 'تەستىق كۈتىۋاتىدۇ',
        checked : 'تەستىقلاندى',
        rejected:'قايتۇرۇلدى',
        det:'ھەممە',

    },
    xls_header:{
        supplier_xls_title:'تەمىنلىگۈچى باشقۇرۇش چىقىرىش ئۇچۇرلىرى'
    },
    quick:{
        today:'بۈگۈن ',
        last_week :'يېقىنقى بىر ھەپتە',
        last_month :'يېقىنقى بىر ئاي',
        last_three_months :'يېقىنقى ئۈچ ئاي',
        last_six_months :'يېقىنقى ئالتە ئاي',
        to:'دىن',

        detail:'تەپسىلاتى',


        weekPlaceholders:'ۋاقىت تاللاڭ',

        start_placeholder:'باشلىنىش ۋاقتى',
        end_placeholder:'ئاياغلىنىش ۋاقتى',
    },
    checkin_date:{
        work_shift:'بارماق باسقان ۋاقىت',
        check_in_time_value:'بارماق بېسىش ۋاقتى',
        type_value:'تىپى',
        address:'بارماق باسقان ئورۇن',
        distance:'ئارلىق',
        date:'چىسلا',
    }
}