<template>
    <div :style="$lang == 2 ? {direction:'ltr'} : {direction:'rtl'}">
        <Card>
            <p slot="title">{{$t('menu_name.product_out_percent')}}</p>

            <div style="padding: 10px">

                <el-table
                        :data="tableData"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        max-height="750"
                        style="width: 100%"
                        v-loading="tableLoading">
                    <el-table-column
                            prop="id"
                            type="index"
                            label="ID">
                    </el-table-column>
                    <el-table-column
                            prop="percent"
                            :label="$t('customer_table.percent_name')">
                        <template slot-scope="scope">
                            {{scope.row.percent}}% <!---/ {{scope.row.percent / 100}}元--->
                        </template>
                    </el-table-column>

                    <el-table-column
                            :label="$t('customer_table.operation')"
                            width="250">
                        <template slot-scope="scope">
                            <Row style="text-align: center;justify-items: center;margin: 0 auto" :gutter="3">
                                <Col :span="23">
                                    <el-button icon="el-icon-edit" @click="handleEdit(scope.row)" type="primary" size="small">
                                        {{$t("customer_table.edit_submit")}}
                                    </el-button>
                                </Col>
                            </Row>

                        </template>
                    </el-table-column>
                </el-table>

            </div>
        </Card>
        <el-dialog
                :title="type == 1 ? $t('system_create') : $t('system_edit')"
                :visible.sync="dialogVisible"
                width="70%"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <Form ref="customerRef" :style="$lang == 2 ? {direction:'ltr'} : {direction:'rtl'}">
                <Row :gutter="20">
                    <Col :span="24">
                        <FormItem>
                            <Input v-model="percent" @on-enter="customerCreate(percent)" placeholder="请输入出货价百分比，必须整数" type="text" size="large"></Input>
                        </FormItem>
                    </Col>
                </Row>

            </Form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose">{{$t('cancel_button')}}</el-button>
                <el-button type="primary" @click="customerCreate(percent)">{{$t('confrim_button')}}</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    export default {
        name: "productOutPercent",
        data(){
            return{
                tableLoading:true,
                tableData: [],

                dialogVisible:false,

                categorys:[],

                total:0,
                pagesize:10,
                currentPage:1,


                percent:'',
                id:'',

                //type  判断时新增还是编辑
                type:0,
                customerForm:{
                    name_ug:'',
                    name_zh:'',
                    weight:'',
                    state:1,
                },
            }
        },
        mounted() {
            this.show();
        },
        methods:{
            show(){
                this.$http.get('cms_api/ParcentList',{
                    params:{
                        keyword:'',
                    }
                }).then((res)=>{
                    this.tableData = res.data
                })
            },
            handleEdit(row){
                this.percent = row.percent
                this.id = row.id
                this.dialogVisible = true
            },
            handleClose(){
                this.dialogVisible = false;
            },
            customerCreate(percent){
                if (percent == ''){
                    this.$msMessage.error(this.$i18n.t("system_require"))
                }else if (percent <= 0){
                    this.$msMessage.error(this.$i18n.t("is_zro_xiao") + percent)
                }else if (percent > 100){
                    this.$msMessage.error(this.$i18n.t("is_wan_xiao") + percent)
                }else{
                    let from = {
                        id:this.id,
                        percent:percent
                    }
                    this.$http.post("cms_api/percentUpdate",from)
                        .then((res)=>{
                            this.show()
                            this.dialogVisible = false
                        })
                }

            }
        },
    }
</script>

<style scoped>
    /deep/.el-dialog{
        border-radius: 10px;
    }
    /deep/ .el-upload--picture-card .upload_avatar{
        border-radius: 120px;
    }
</style>